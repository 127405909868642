import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../Constants/firebaseConfig";

export const app = initializeApp(firebaseConfig);

export const formatDate = (date) => {
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};

export function response(status, message, response) {
  return {
    status,
    message,
    response,
  };
}
