import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CursorProvider from "./Components/Cursor";
import { lightTheme } from "./Constants/Theme";
import LoadingAnimation from "./Components/LoadingAnimation";
import { motion } from "framer-motion";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const theme = createTheme(lightTheme);

function LoadingScreen() {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: "#fff",
      }}
    >
      <Stack spacing={2} direction="row">
        <motion.div
          style={{ width: "128px" }}
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 3 }}
        >
          <LoadingAnimation />
        </motion.div>
        <motion.div
          style={{
            width: "0px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
          initial={{ width: 0, opacity: 1 }}
          animate={{ width: "170px", opacity: 0 }}
          transition={{ delay: 2, opacity: { delay: 3 } }}
        >
          <Typography variant="h3" color="initial" textOverflow="hidden">
            Kavitha Contractors
          </Typography>
        </motion.div>
      </Stack>
    </Box>
  );
}

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <CursorProvider>
      <ThemeProvider theme={theme}>
        {/* <LoadingScreen /> */}
        {isLoading ? <LoadingScreen /> : <LazyScroller />}
      </ThemeProvider>
    </CursorProvider>
  );
}

const LazyScroller = React.lazy(() => import("./Pages/Scroller"));
