import React from "react";

export default function LoadingAnimation() {
  return (
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      style={{ enableBackground: "new 0 0 200 200" }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
        .no-fill {
          fill: none;
        }
        .white-stroke {
          stroke: #ffffff;
          stroke-width: 6;
          stroke-miterlimit: 10;
        }
        .white-fill {
          fill: #ffffff;
        }
        @keyframes build {
          0% {
            transform: scaleY(0);
            transform-origin: bottom;
          }
          100% {
            transform: scaleY(1);
            transform-origin: bottom;
          }
        }
        .building {
          animation: build 1.5s ease-in-out forwards;
          animation-fill-mode: forwards;
        }
        @keyframes drawRing {
          0% {
            stroke-dasharray: 0 400;
            stroke-dashoffset: 300;
            stroke-width: 0;
          }
          100% {
            stroke-dasharray: 400 0;
            stroke-dashoffset: 500;
            stroke-width: 6;
          }
        }
        .ring {
          animation: drawRing 1.5s ease-in-out forwards;
          animation-fill-mode: forwards;
          stroke-dasharray: 0 400;
        }
        #building1 {
          animation-duration: 0.3s;
        }
        #building2 {
          animation-duration: .7s;
        }
        #building3 {
          animation-duration: 1s;
        }
      `}
      </style>
      <g id="logo">
        <path
          id="background"
          d="M184,200H16c-8.8,0-16-7.2-16-16V16C0,7.2,7.2,0,16,0h168c8.8,0,16,7.2,16,16v168
          C200,192.8,192.8,200,184,200z"
        />
        <g id="ring">
          <defs>
            <polygon
              id="clip-path-1"
              points="0,0 60.8,0 60.8,149.7 138.8,149.7 138.8,0 200,0 200,200 0,200"
            />
          </defs>
          <clipPath id="cp-ring">
            <use xlinkHref="#clip-path-1" style={{ overflow: "visible" }} />
          </clipPath>
          <circle
            style={{ clipPath: "url(#cp-ring)" }}
            className="no-fill white-stroke ring"
            cx="99.6"
            cy="123.3"
            r="63.9"
          />
        </g>
        <g id="buildings">
          <defs>
            <path
              id="clip-path-2"
              d="M163.9,122.9c0,35.3-28.6,63.9-63.9,63.9
              s-63.9-28.6-63.9-63.9c0-20.6,9.7-38.8,24.8-50.5V0h77.9v72.1C154.1,83.8,163.9,102.2,163.9,122.9z"
            />
          </defs>
          <clipPath id="cp-buildings">
            <use xlinkHref="#clip-path-2" style={{ overflow: "visible" }} />
          </clipPath>
          <g id="building" style={{ clipPath: "url(#cp-buildings)" }}>
            <polygon
              id="building1"
              className="white-fill building"
              points="84.8,190.2 66.8,190.2 66.8,114.4 84.8,102.7"
            />
            <polygon
              id="building2"
              className="white-fill building"
              points="108.8,190.2 90.8,190.2 90.8,67.1 108.8,56.4"
            />
            <polygon
              id="building3"
              className="white-fill building"
              points="132.8,190.2 114.8,190.2 114.8,20.5 132.8,10.1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
