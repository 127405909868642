import { useRef, useContext } from "react";
import { motion, useSpring, useMotionValue } from "framer-motion";
import { useEffect, createContext, useState } from "react";

const CursorContext = createContext();

const cursor = {
  grown: { width: "20px", height: "20px", scale: 10 },
  visible: { width: "20px", height: "20px" },
  hidden: { width: "0px", height: "0px" },
};

export default function CursorProvider(props) {
  const [child, setChild] = useState("");
  const [IsTouchDevice, setIsTouchDevice] = useState(false);

  const [variant, setVariant] = useState(1);
  function UpdateChild(val) {
    if (val != null) {
      setChild(val);
    } else {
      setChild("");
    }
  }

  const ref = useRef(null);
  const springConfig = {
    damping: 30,
    stiffness: 700,
    restDelta: 0.05,
  };
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const dx = useSpring(x, springConfig);
  const dy = useSpring(y, springConfig);

  useEffect(() => {
    var isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    setIsTouchDevice(isTouchDevice);
    if (!ref.current || isTouchDevice) return;
    const handlePointerMove = ({ clientX, clientY }) => {
      const element = ref.current;
      const udx = clientX - element.offsetLeft - element.offsetWidth / 2;
      const udy = clientY - element.offsetTop - element.offsetHeight / 2;
      // setPoint({ x, y });
      x.set(udx);
      y.set(udy);
    };

    window.addEventListener("pointermove", handlePointerMove);

    return () => window.removeEventListener("pointermove", handlePointerMove);
  }, []);

  return (
    <CursorContext.Provider value={{ setVariant, UpdateChild }}>
      <div style={{ pointerEvents: "none" }}>
        {!IsTouchDevice && (
          <motion.div
            style={{
              x: dx,
              position: "fixed",
              y: dy,
              borderRadius: "50%",
              backgroundColor: "black",
              // mixBlendMode: "difference",
              zIndex: "999",
            }}
            animate={
              variant == 0 ? "hidden" : variant == 1 ? "visible" : "grown"
            }
            transition={{ type: "tween" }}
            variants={cursor}
            ref={ref}
          >
            {child}
          </motion.div>
        )}
      </div>
      {props.children}
    </CursorContext.Provider>
  );
}

export { CursorContext };
