import { app, response } from "./Firebase";
import {
  doc,
  deleteDoc,
  collection,
  getDocs,
  getDoc,
  getFirestore,
  setDoc,
  addDoc,
} from "firebase/firestore";

const firestoreDB = getFirestore(app);

export async function _deleteDocumentInCollection(collectionName, documentId) {
  try {
    const documentReference = doc(firestoreDB, collectionName, documentId);
    await deleteDoc(documentReference);
    return response(true, "Document deleted successfully", null);
  } catch (error) {
    console.error("Error deleting document:", error);
    return response(false, "Error deleting document", error.message);
  }
}

export async function _readAllDocumentsInCollection(collectionName) {
  try {
    const collectionReference = collection(firestoreDB, collectionName);
    const querySnapshot = await getDocs(collectionReference);
    const documentsData = querySnapshot.docs.map((doc) => doc.data());
    return response(true, "Documents retrieved successfully", documentsData);
  } catch (error) {
    console.error("Error reading documents:", error);
    return response(false, "Error reading documents", error.message);
  }
}

export async function _readDocumentInCollectionById(
  collectionName,
  documentId
) {
  try {
    const documentReference = doc(firestoreDB, collectionName, documentId);
    const documentSnapshot = await getDoc(documentReference);
    if (documentSnapshot.exists()) {
      const documentData = documentSnapshot.data();
      return response(true, "Document retrieved successfully", documentData);
    } else {
      return response(false, "Document does not exist", null);
    }
  } catch (error) {
    console.error("Error reading document:", error);
    return response(false, "Error reading document", error.message);
  }
}

export async function _addOrUpdateDocumentInCollection(
  collectionName,
  newOrUpdatedDocument,
  data
) {
  try {
    const collectionReference = collection(firestoreDB, collectionName);
    await setDoc(
      doc(collectionReference, newOrUpdatedDocument.toString()),
      data
    );
    return response(true, "Document added/updated successfully", null);
  } catch (error) {
    console.error("Error creating/updating document:", error);
    return response(false, "Error creating/updating document", error.message);
  }
}

export async function _createCollectionAndAddData(collectionName, data) {
  try {
    const collectionReference = collection(firestoreDB, collectionName);
    const docRef = await addDoc(collectionReference, data);
    console.log("Document added with ID: ", docRef.id);
    return response(true, "Document added successfully", docRef.id);
  } catch (error) {
    console.error("Error adding document:", error);
    return response(false, "Error adding document", error.message);
  }
}
