import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  updateProfile,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  // onAuthStateChanged,
} from "firebase/auth";
import { app, response } from "./Firebase";
import { _addOrUpdateDocumentInCollection } from "./Firestore";

export const auth = getAuth(app);

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Password Authentication
function createCredential(email, password) {
  return EmailAuthProvider.credential(email, password);
}

//User Management
export function _getCurrentUserProfileDetails(user) {
  try {
    if (!user) {
      user = auth.currentUser;
    }
    const { displayName, email, photoURL, emailVerified, uid } = user;
    const responseObject = {
      displayName,
      email,
      photoURL,
      emailVerified,
      uid,
    };
    return response(
      true,
      "User profile details retrieved successfully",
      responseObject
    );
  } catch (error) {
    console.error("Error retrieving user profile details:", error);
    return response(false, error.message, error);
  }
}

export async function _updateCurrentUserProfile(displayName, photoURL) {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("No user logged in");
    }
    await updateProfile(user, { displayName, photoURL });
    console.log("Profile updated successfully!");
    return response(true, "Profile updated successfully", null);
  } catch (error) {
    console.error("Error updating profile:", error);
    return response(false, error.message, error);
  }
}

async function _updateCurrentUserEmail(user, newEmail) {
  try {
    if (!user) {
      user = auth.currentUser;
    }
    await updateEmail(user, newEmail);
    console.log("Email updated successfully!");
    return response(true, "Email updated successfully", null);
  } catch (error) {
    console.error("Error updating email:", error);
    return response(false, error.message, error);
  }
}

async function _updateCurrentUserPassword(user, newPassword, oldPassword) {
  try {
    if (!user) {
      user = auth.currentUser;
    }
    const credential = createCredential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    console.log("Password updated successfully!");
    return response(true, "Password updated successfully", null);
  } catch (error) {
    console.error("Error updating password:", error);
    return response(false, error.message, error);
  }
}

async function _deleteCurrentUser(user) {
  try {
    if (!user) {
      user = auth.currentUser;
    }

    await deleteUser(user);
    console.log("User deleted successfully!");
    return response(true, "User deleted successfully", null);
  } catch (error) {
    console.error("Error deleting user:", error);
    return response(false, error.message, error);
  }
}

export async function _addNewUser(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    let user = userCredential.user;
    if (user) {
      return response(true, "User created successfully", user);
    }
  } catch (error) {
    console.error("Error creating user:", error);
    return response(false, error.message, error);
  }
}

export async function _signIn(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return response(true, "User signed in successfully", user);
  } catch (error) {
    console.error("Error signing in:", error);
    return response(false, error.message, error);
  }
}

export async function _signOut() {
  try {
    await signOut(auth);
    console.log("Sign-out successful.");
    return response(true, "Sign-out successful", null);
  } catch (error) {
    console.error("Error signing out:", error);
    return response(false, error.message, error);
  }
}

// export default function useAuthState() {
//   const [authenticatedUser, setUser] = useState(null);
//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((currentUser) => {
//       setUser(currentUser);
//     });

//     return () => unsubscribe();
//   }, []);

//   return authenticatedUser;
// }
